import React from "react";
import Layout from "../components/layout";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/seo.js";
import "../styles/base.scss";
import "../styles/styles.scss";
import homeStyles from "./index.module.scss";
import buttonStyles from "../components/buttons.module.scss";

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___published_date] }
      ) {
        edges {
          node {
            html
            frontmatter {
              title
              published_date
              packUrl
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                    src
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  const coverList =
    data.allMarkdownRemark.edges &&
    data.allMarkdownRemark.edges.map((edge, i) => {
      let featuredImgFluid =
        edge.node.frontmatter.featuredImage.childImageSharp.fluid;
      return (
        <li key={i} className={homeStyles.cover}>
          <h2>{edge.node.frontmatter.title}</h2>
          <div className={homeStyles.buttons}>
            {edge.node.frontmatter.packUrl ? (
              <Link
                className={buttonStyles.button}
                to={edge.node.frontmatter.packUrl}
              >
                Get the pack
              </Link>
            ) : null}
          </div>
          <Link to={edge.node.frontmatter.packUrl}>
            <Img
              className={homeStyles.coverImage}
              fluid={featuredImgFluid}
              alt={edge.node.frontmatter.title}
            />
          </Link>
          <div
            className={homeStyles.content}
            dangerouslySetInnerHTML={{ __html: edge.node.html }}
          />
          <div className={homeStyles.buttons}>
            {edge.node.frontmatter.packUrl ? (
              <Link
                className={buttonStyles.button}
                to={edge.node.frontmatter.packUrl}
              >
                Get the pack
              </Link>
            ) : null}
          </div>
        </li>
      );
    });

  return (
    <Layout>
      <SEO title="Notion Covers" />
      <h1>Personalize your workspace with custom covers for Notion.</h1>

      <hr/>
      <div className={homeStyles.coverListWrapper}>
        <ul className={homeStyles.coverList}>{coverList}</ul>
      </div>
    </Layout>
  );
};

export default Home;
